.swiper-container {
  width: 100%;
  padding-top: 400px;
  padding-bottom: 50px;
  padding-left: 650px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 260px;
  height: auto;
  margin-right: 80px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
