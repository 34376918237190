.col1Div {
  overflow: hidden;
}

.hover1E {
  object-fit: cover;
  transition: all 0.3s ease;
}

.hover1E:hover {
  transform: scale(1.2);
}
